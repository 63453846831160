import React from 'react'
import { useLocation } from "@reach/router";
import { StaticImage } from 'gatsby-plugin-image';


  const PortfolioSection = () => {

    const location = useLocation();
    const path = location.pathname;
  
    const getHeadingTag = () => {
      if (path === "/contact-us/") {
        return "h3";
      } else {
        return "h2"; // Default heading
      }
    };
  
    const HeadingTag = getHeadingTag();


    return (
      <section className="portfoliosec abtus">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <HeadingTag className="heading-h2 mt-md-5">We are growing everyday <br></br>
            <span>Client Base</span></HeadingTag>
          </div>
          <div className="quick text-center"> 
               <StaticImage 
               src="https://coinsclone.mo.cloudinary.net/images/aboutus/client-base.webp"
               alt="Client Base"
               width={1110}
               />
          </div>
        </div>
      </section>
    )
}

export default PortfolioSection